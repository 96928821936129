
import { Options, Vue } from "vue-class-component";
import HelloWorld from "@/components/HelloWorld.vue"; // @ is an alias to /src
import Navbar from "@/components/Navbar.vue";

@Options({
  components: {
    HelloWorld,
    Navbar,
  },
})
export default class Homepage extends Vue {}
